@import 'bootstrap';
@import 'highlight.js/styles/github.css';

body {
    font-family: "Noto Sans Packaged", "Noto Sans", sans-serif;
}

.status-passed {
    color: #198819;
}


.status-running {
    color: #d3c938;
}


.status-failed {
    color: #d33c22;
}

.text-monospaced {
    font-size: 12pt;
    font-family: monospace;
    margin: 0 0 8px 0;
    border-bottom: 1px solid black;
}

.nav-image {
    height: 64px;
    padding: 4px;
}

.link-btn, .link-btn:visited {
    color:white;
    text-decoration: none;
    padding: 8px;
}

.link-btn:hover {
    background-color: #ffffff2d;
    border-radius: 8px;
}

div#argusApp {
    position: relative;
}

div#argusErrors {
    position: fixed;
    z-index: 999999;
}

.bg-main {
    background-color: #f9f9f9;
}

.bg-test-error {
    background-color: #e65c00;
}

.text-test-error {
    color: #e65c00;
}


.bg-lighter {
    background-color: #e5e5e5;
}

.bg-light-one {
    background-color: #eaeaea;
}

.bg-light-two {
    background-color: #ededed;
}

.bg-light-three {
    background-color: #f5f5f5;
}

div#notificationCounter {
    margin-top: -12px;
    margin-left: -12px;
}

.user-mention {
    font-weight: 600;
    color: black;
}

.user-mention:hover {
    text-decoration: underline;
}

.user-mention-self {
    background-color: #fff67b;
}

.bg-not-planned {
    background-color: #b3b3b3;
}

.text-not-planned {
    color: #b3b3b3;
}

.btn-orange {
    --bs-btn-color: #fff;
    --bs-btn-bg: #f35f1b;
    --bs-btn-border-color: #f85d2e;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #aa3804;
    --bs-btn-hover-border-color: #853507;
    --bs-btn-focus-shadow-rgb: 225, 83, 97;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #ff5507;
    --bs-btn-active-border-color: #f85d2e;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #af3700;
    --bs-btn-disabled-border-color: #c94b25;
}

.btn-testruns-open {
    --bs-btn-color: #1f1f1f;
    --bs-btn-bg: #dce2ec;
    --bs-btn-border-color: #dce2ec;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #305fb6;
    --bs-btn-hover-border-color: #305fb6;
    --bs-btn-focus-shadow-rgb: 225, 83, 97;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #3d5f9c;
    --bs-btn-active-border-color: #3d5f9c;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #87aef7;
    --bs-btn-disabled-border-color: #87aef7;
}

.table-orange {
    --bs-table-color: #000;
    --bs-table-bg: #fcddc1;
    --bs-table-border-color: #fcddc1;
    --bs-table-striped-bg: #fcddc1;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #fcddc1;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #fcddc1;
    --bs-table-hover-color: #000;
    color: var(--bs-table-color);
    border-color: var(--bs-table-border-color);
}

.img-profile {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: rgb(163, 163, 163);
    background-clip: border-box;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
